
.online-store-detail{
  height: 100%;
  display: flex;
  flex-direction: column;
  .store-set-scroll{
    display:flex;
    flex:1;
    height:1%;
    flex-direction: column;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
      .el-scrollbar__view{
        padding: 20px 0;
        box-sizing: border-box;
      }
      .store-set-table{
        display: flex;
        flex-direction: column;
        border: 1px solid #EEEEEE;
        .set-table-header{
          height: 60px;
          display: flex;
          background: #F6F6F6;
          .table-header-item{
            flex: 1;
            width: 1%;
            span{
              color: #666666;
              font-size: 16px;
              line-height: 60px;
            }
            &:first-of-type{
              span{
                margin-left: 58px;
              }
            }
            &:last-of-type{
              text-align: center;
            }
          }
        }
        .set-table-body{
          display: flex;
          border-top: 1px solid #E5E5E5;
          height: 50px;
          .table-body-item{
            flex: 1;
            display: flex;
            width: 1%;
            span{
              color: #333;
              font-size: 16px;
              line-height: 50px;
            }
            &:first-of-type{
              span{
                margin-left: 58px;
              }
            }
            &:last-of-type{
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
.img-mask-layer{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top:0;
  background: rgba(0,0,0,0.3);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  .img-box{
    position: relative;
    img{
      max-width: 500px;
    }
    i{
      position: absolute;
      font-size: 50px;
      line-height: 1;
      color: #FFFFFF;
      right: -100px;
      top: -66px;
      cursor: pointer;
    }
  }
  .last-img, .next-img{
    position: absolute;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    &.last-img{
      left: 30px;
    }
    &.next-img{
      right: 30px;
    }
    i{
      line-height: 1;
      font-size: 50px;
      color: #B2B2B2;
    }
  }
}
