
.answer-content{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
  .main-wrapper-content{
    flex: 1;
    height: 1%;
    display: flex;
    flex-direction: column;
  }
}
